import React, { Component, useState } from 'react';
import {
   Table,
   TableHead,
   TableHeader,
   TableRow,
   TableBody,
   TableCell,
   TableContainer, 
   TableToolbar,
   TableToolbarContent,
   TableExpandHeader,
   TableExpandRow,
   TableExpandedRow,
   DataTable,
   Button,
   ButtonSet,
   Form,
   TextInput,
   Modal,
   Pagination,
   Select,
   SelectItem,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

class Team extends Component {
   constructor(props) {
      super(props);
      this.state = {
         CreateFormVisible: false,
         CreateFormName: "",
         CreateFormDescription: "",
         CreateFormKind: "",
         MemberFormVisible: false,
  	 MemberFormHeader: ["Name","First Name","Last Name","Organisation","Status","Action"],
         ScoresFormVisible: false,
         ScoreRows: 0,
         ScoreColumns: 0,
         id: 0,
 	 contest: "",
         ContestList: [],           // list of contests
         ContestSelected: 0,        // ID of selected contest
         ContestSelection: {},      // selected contest object for dropdown 
         Name: "",
         Description : "",
         MaxTeamSize: 0,
         Enabled: 0,
 	 Teams: [], 
         Team: 0,                   // My team ID
         TeamData: "", 
         Status: 0,                 // Accepted or Pending  
         header:["Rank","Name","Score","Scorecard","Status","Members"],
//         Login:"",
         Page: 1,
         PageSize: 25
      };
      this.handleChangeName = this.handleChangeName.bind(this);
      this.handleChangeDescription = this.handleChangeDescription.bind(this);
      this.handleChangeKind = this.handleChangeKind.bind(this);
      this.handleCreateSubmit = this.handleCreateSubmit.bind(this);
      this.handleOnExpandRow = this.handleOnExpandRow.bind(this);
      this.showMemberForm = this.showMemberForm.bind(this);
      this.showScoresForm = this.showScoresForm.bind(this);
      this.changePage = this.changePage.bind(this);
   }

   async getContestData (contest_ID) {
      await axios.post(config.api_hostname + ':' + config.api_port + '/api/get_contest_teams',
                       { ID: contest_ID },
                       { headers: { 'content-type': 'application/x-www-form-urlencoded',
//                                    'Access-Control-Allow-Origin' : '*',
//                                    'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept'
                        }
                     })
         .then((response) => {
            const data = response.data;
            for (var i = 0; i < data.Teams.length; i++)
               data.Teams[i].IsExpanded = false;
            this.setState({ Name: data.Name, Description: data.Description, MaxTeamSize: data.MaxTeamSize, Teams: data.Teams, Team: data.Team, Status: data.Status, Enabled: data.Enabled});
         })
   }

   componentDidMount() {
      this.setState({ Login: this.props.Login });
      this.setState({ ContestSelected: this.props.Selection });
      var data = this.getContestData(this.props.Selection);
   }
   handleOnExpandRow(rowId) {
      var c = this.state.Teams;
      for (var i = 0; i < c.length; i++) 
         if (c[i].ID === rowId)
            c[i].IsExpanded = ! c[i].IsExpanded;
      this.setState( { Teams: c } );
   };
   showCreateForm = () => {
      this.setState({ CreateFormVisible: true  });
   }
   hideCreateForm = () => {
      this.setState({ CreateFormVisible: false  });
   }
   handleChangeName(event) {
     this.setState({CreateFormName: event.target.value});
   }
   handleChangeDescription(event) {
     this.setState({CreateFormDescription: event.target.value});
   }
   handleChangeKind(event) {
     this.setState({CreateFormKind: event.target.value});
   }
   handleCreateSubmit(event) {
      var msg = "";
      if (this.state.CreateFormName.length < 8)  msg = msg + "Name too short, must be at least 8 characters\r\n";
      if (this.state.CreateFormName.length > 64) msg = msg + "Name too long, maximum 64 characters\r\n";
      if (this.state.CreateFormDescription.length < 8) msg = msg + "Description too short, must be at least 8 characters\r\n";
      if (this.state.CreateFormDescription.length > 256) msg = msg + "Description too long, maximum 256 characters\r\n";
      if ( msg.length > 0 )
         alert(msg);
      else {
         axios.post(config.api_hostname + ':' + config.api_port + '/api/create_team',
                       { Leader: this.state.Login,
                         Name: this.state.CreateFormName,
                         Description: this.state.CreateFormDescription,
                         Kind: this.state.CreateFormKind,
                         Contest: this.state.ContestSelected },
            //             { headers: { 'content-type': 'application/x-www-form-urlencoded',
                  //                                             'Access-Control-Allow-Origin' : '*',
                 //                                              'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept'
                  //        }   }
                     )
         .then((response) => {
            this.setState({ CreateFormVisible: false, Team: response.insertId });
            this.getContestData(this.state.ContestSelected);
            window.location.reload(true);
         })
      }
   }
   handleRequestToJoin (event) {
      axios.post(config.api_hostname + ':' + config.api_port + '/api/join_team',
                       { Member: this.props.Login,
                         Team: event },
                     //  { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
                     )
         .then((response) => {
            this.getContestData();
            this.setState({ CreateFormVisible: false  });
            window.location.reload(true);
         })
   }
   showMemberForm (team) {
      axios.post(config.api_hostname + ':' + config.api_port + '/api/get_team_member',
                       { Team: team } /*,
                       { headers: { 'content-type': 'application/x-www-form-urlencoded' } }*/ )
      .then((response) => {
         for (var i = 0; i < response.data.Members.length; i++)
            response.data.Members[i].Action = "";
         this.setState({ TeamData: response.data, MemberFormVisible: true });
      })
   }
   hideMemberForm () {
      this.setState({ MemberFormVisible: false  });
   }
   handleMemberFormAccept (id) {
      axios.post(config.api_hostname + ':' + config.api_port + '/api/update_team_member',
                       { Team: id,
                         Accept: "true" },
                     //  { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
                     )
         .then((response) => {
            this.hideMemberForm(this.state.TeamData.ID);
            this.showMemberForm(this.state.TeamData.ID);
         })
   }
   handleMemberFormDecline = (id) => {
      axios.post(config.api_hostname + ':' + config.api_port + '/api/update_team_member',
                       { Team: id,
                         Accept: "false" },
                     //  { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
                     )
         .then((response) => {
            this.hideMemberForm(this.state.TeamData.ID);
            this.showMemberForm(this.state.TeamData.ID);
         })
   }
   changePage(PageInfo) {
      this.setState({ Page: PageInfo.page, PageSize: PageInfo.pageSize });
   }

   showScoresForm (team) {
      axios.post(config.api_hostname + ':' + config.api_port + '/api/get_team_scores',
                       { Team: team } /*,
   { headers: { 'content-type': 'application/x-www-form-urlencoded' } } */)
      .then((response) => {
         this.setState({ TeamData: response.data, ScoresFormVisible: true  });
      })
   }
   hideScoresForm = () => {
      this.setState({ ScoresFormVisible: false  });
   }

   render() {
      if (this.state.ContestSelected !== this.props.Selection) {
         var data = this.getContestData(this.props.Selection);
         this.setState({ ContestSelected: this.props.Selection});
      }
 
      return (
         <div>
            <TableContainer title={"Contest: " + this.state.Name} description={this.state.Description}>
               <TableToolbar aria-label="data table toolbar">
                  <TableToolbarContent>
                     <Button onClick={() => this.showCreateForm()} disabled={(this.state.Team > 0)}>Create New Team</Button>
                  </TableToolbarContent>
               </TableToolbar>
               <Table size='compact' useZebraStyles={false}>
                  <TableHead>
                     <TableRow>
                        <TableExpandHeader />
                           { this.state.header.map((header) => (
                              <TableHeader id={header.key} key={header}>
                                 {header}
                              </TableHeader>
                           ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {this.state.Teams.slice((this.state.Page - 1) * this.state.PageSize).slice(0,this.state.PageSize).map((row) => (
                        <React.Fragment key={row.id}>
                        <TableExpandRow isExpanded={row['IsExpanded']} onExpand={() => this.handleOnExpandRow(row['ID'])} key={row['ID']}>
                           <TableCell>{row['Sequence']}</TableCell>
                           <TableCell>{row['Name']}</TableCell>
                           <TableCell>{0+row['Total']}%</TableCell>
                           <TableCell><Link onClick={() => this.showScoresForm(row["ID"])} >Scores</Link></TableCell>
                           { (this.state.Team > 0) && 
                             (this.state.Team === row["ID"]) && 
                             (this.state.Status === 1) && 
                             (row["Leader"] === true) &&
                             (<TableCell>Owner</TableCell>)
                           }
                           { (this.state.Team > 0) && 
                             (this.state.Team === row["ID"]) && 
                             (this.state.Status === 1) && 
                             (row["Leader"] === false) &&
                             (<TableCell>Owner</TableCell>)
                           }
                           { (this.state.Team > 0) && 
                             (this.state.Team === row["ID"]) && 
                             (this.state.Status === 0) && 
                             (<TableCell>Pending Request</TableCell>)
                           }
                           { (this.state.Team > 0) && 
                             (this.state.Team !== row["ID"]) && 
                             (<TableCell> </TableCell>)
                           }
                           { (this.state.Team === 0) && (this.state.Enabled === 1) &&
                             (this.state.MaxTeamSize > row["TeamSize"]) &&
                             (<TableCell>{row["TeamSize"]} Members <Link onClick={() => this.handleRequestToJoin(row["ID"])} >Request to Join</Link></TableCell>)
                           }
                           { (this.state.Team === 0) && (this.state.Enabled === 1) &&
                             (this.state.MaxTeamSize <= row["TeamSize"]) &&
                             (<TableCell>Full</TableCell>)
                           }
                           { (this.state.Team === 0) && (this.state.Enabled === 0) &&
                             (<TableCell></TableCell>)
                           }
                           <TableCell><Link onClick={() => this.showMemberForm(row["ID"])} >{row["TeamSize"]} Members </Link></TableCell>
                        </TableExpandRow>
                        <TableExpandedRow colSpan={this.state.header.length+1 } className="demo-expanded-td">
                           <h6>Description</h6>
                           <div>{row["Description"]}</div>
                        </TableExpandedRow>
                        </React.Fragment>
                     ))}
                  </TableBody>
               </Table>   
            </TableContainer>
            <Pagination backwardText="Previous page" forwardText="Next page" itemsPerPageText="Items per page:" onChange={this.changePage} page={this.state.Page} pageSize={this.state.PageSize} pageSizes={[25,50,75]} size="medium" totalItems={this.state.Teams.length} />

            {this.state.CreateFormVisible && (
            <Modal
               open
               modalHeading="Add a new team"
               modalLabel="Create Team"
               primaryButtonText="Add"
               secondaryButtonText="Cancel"
               onRequestSubmit={() => this.handleCreateSubmit()}
               onSecondarySubmit={() => this.hideCreateForm()}
               onRequestClose={() => this.hideCreateForm()}>
               <p style={{ marginBottom: '1rem' }}>
                  Add a new team to the contest. By default you will be the team leader.
               </p>
               <TextInput
                  data-modal-primary-focus
                  id="team_name"
                  labelText="Team name"
                  placeholder="Write the creative name for your team"
                  style={{ marginBottom: '1rem' }}
                  onChange={this.handleChangeName}
               />
               <TextInput
                  data-modal-primary-focus
                  id="team_description"
                  labelText="Short description"
                  placeholder="What does your team stand for?"
                  style={{ marginBottom: '1rem' }}
                  onChange={this.handleChangeDescription}
               />
               <Select
                  labelText="Kind"
                  onChange={this.handleChangeKind}
               >
                  <SelectItem value="1" text="Athletic" />
                  <SelectItem value="2" text="Recreational" />
               </Select>
            </Modal>
            )}

            {this.state.MemberFormVisible && (
            <Modal
               open
               modalHeading={this.state.TeamData.Name}
               modalLabel="Team"
               primaryButtonText="Close"
               onRequestSubmit={() => this.hideMemberForm()}
               onRequestClose={() => this.hideMemberForm()}>
               <p style={{ marginBottom: '1rem' }}>
                  {this.state.TeamData.Description}
               </p>
               <p style={{ marginBottom: '1rem' }}>
                  Pending member join requests can be accepted / declined below.
               </p>
               <Table size="compact" useZebraStyles={false}>
                  <TableHead>
                     <TableRow>
                        {this.state.MemberFormHeader.map((header) => (
                           <TableHeader id={header.key} key={header}>
                              {header}
                           </TableHeader>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                        {this.state.TeamData.Members.map((row) => (
                           <TableRow key={row.id}>
            {Object.keys(row)
              .filter((key) => key !== 'ID')
              .map((key) => {
                if (key === "Status")
                   if (row["Status"] === 0)
                      return <TableCell key={key}>Pending</TableCell>
                   else
                      return <TableCell key={key}>Accepted</TableCell>
                else
                   if (key === "Action") {
                      if ((this.state.TeamData.Leader) && (row["Status"] === 0))
                         return <TableCell><Link onClick={() => this.handleMemberFormAccept(row["ID"])} >Accept</Link> or <Link onClick={() => this.handleMemberFormDecline(row["ID"])} >Decline</Link></TableCell>
                      else
                         return <TableCell></TableCell>             
                   }
                   else
                      return <TableCell key={key}>{row[key]}</TableCell>
              })}
                  </TableRow>
               ))}
            </TableBody>
         </Table>
            </Modal>
            )}

            {this.state.ScoresFormVisible && (
            <Modal
               open
               modalHeading={this.state.TeamData.Name + " : " + this.state.TeamData.Total.toFixed(2) + "%"}
               modalLabel="Team Scorecard"
               primaryButtonText="Close"
               onRequestSubmit={() => this.hideScoresForm()}
               onRequestClose={() => this.hideScoresForm()}>
               <Table size="compact" useZebraStyles={false}>
                     <TableHead>
                        <TableRow>
                           <TableHeader>Challenge</TableHeader>
                           <TableHeader>Weight</TableHeader>
                           <TableHeader>Value</TableHeader>
                           <TableHeader>Score</TableHeader>
                           <TableHeader>Total</TableHeader>
                           <TableHeader>Details</TableHeader>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                     {this.state.TeamData.Scores.map((row) => ( row.map((col) => ( (col !== null) && (
                        <TableRow>
                              <TableCell>{col.Name}</TableCell>
                              <TableCell>{col.Weight}%</TableCell>
                              <TableCell>{col.Value}</TableCell>
                              <TableCell>{col.Score}%</TableCell>
                              <TableCell>{col.Total}%</TableCell>
                              <TableCell>{col.Data}</TableCell>
                        </TableRow>
                     ))) ))}
                  </TableBody>
               </Table>
            </Modal>
            )}


         </div>
      )
   }
}

export default Team;
