import React, { Component } from 'react';
import './app.scss';
import { Content } from 'carbon-components-react';
import PageHeader from './components/PageHeader';
import PageFooter from './components/PageFooter';
import { Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './content/LandingPage';
import { NavigationMenu } from 'carbon-components';

class App extends Component {
   constructor(props) {
      super(props);
      this.setAuth.bind(this);
      this.getAuth.bind(this);
      this.state = {
         Authenticated: false,
         Login: null,
      };
   }

   setAuth = (auth) => {
      if (! this.state.Authenticated) {
         this.setState({ Login: auth });
         if (auth === "") this.setState({ Authenticated: false });
                     else this.setState({ Authenticated: true });
      }                  
   }

   getAuth = () => {
      return(this.state.Login);
   }

   render() {
      return (
            <div class="wrapper">
               <PageHeader {...this.state} getAuth={this.getAuth} />
               <Content>
                  <Routes>
                     <Route exact path="/" element={<LandingPage getAuth={this.getAuth} setAuth={this.setAuth}/>}></Route>
                  </Routes>
               </Content>
               <PageFooter />
            </div>
      );
   }
}

export default App;
