const config = {
//  api_hostname:"https://localhost",
  strava_client_id: 101276,
//  strava_redirect_uri: "http://ibm-challenge.ibm.bogaert.com",
  strava_redirect_uri:"http://9b229e1c-eu-de.lb.appdomain.cloud",
  api_hostname:"https://9b229e1c-eu-de.lb.appdomain.cloud",
  api_port: 443,
};

export default config;
