import React, { Component, useState } from 'react';
import {
   Table,
   TableHead,
   TableHeader,
   TableRow,
   TableBody,
   TableCell,
   TableContainer, 
   TableToolbar,
   TableToolbarContent,
   DataTable,
   Pagination,
} from 'carbon-components-react';
import axios from 'axios';
import config from '../../config';

class Activity extends Component {
   constructor(props) {
      super(props);
      this.state = {
         id: 0,
	 contest: "",
         ContestSelected: 0,        // ID of selected contest
         Name: "",
         Description : "",
  	 Activities: [],
         Leader: false,
         header:["Name","Moving Time","Sport Type","Date","Distance","Photos","Manual"],
         Login:"",
         Page: 1,
         PageSize: 25
      };
      this.changePage = this.changePage.bind(this);
   }

   async getActivityData (contest_ID) {
      if (contest_ID > 0)
         await axios.post(config.api_hostname + ':' + config.api_port + '/api/get_member_activities',
                       { ID: contest_ID },
                       { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then((response) => {
            const data = response.data;
            this.setState({ Name: data.Name, Description: data.Description, Activities: data.Activities });
            return data.Activities;
         }
      )
   }

   componentDidMount() {
      this.setState({ Login: this.props.Login });
      this.setState({ ContestSelected: this.props.Selection });
      var data = this.getActivityData(this.props.Selection);
   }

   changePage(PageInfo) {
      this.setState({ Page: PageInfo.page, PageSize: PageInfo.pageSize });
   }

   render() {
      if (this.state.ContestSelected !== this.props.Selection) {
         var x = this.getActivityData(this.props.Selection);
         this.setState({ ContestSelected: this.props.Selection, Page:1 });
      }

      return (
         <div>
            <TableContainer title={"Activities"} description="List of activities in contest. REFRESH YOUR BROWSER FOR AN UPDATE. Data from Strava arrives delayed by a couple of minutes. Click on the name of the activity to see your data in Strava in a new browser window. Caution: activities created manually do NOT count for the challenge evaluations.">
               <TableToolbar aria-label="data table toolbar">
                  <TableToolbarContent>
                  </TableToolbarContent>
               </TableToolbar>
               <Table size='compact' useZebraStyles={false}>
               <TableHead>
                     <TableRow>
                        {this.state.header.map((header) => (
                           <TableHeader>{header}</TableHeader>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {this.state.Activities.slice((this.state.Page - 1) * this.state.PageSize).slice(0,this.state.PageSize).map((row) => (
                     <TableRow>
                        <TableCell><a href= {"https://www.strava.com/activities/" + row['id']} target="_blank">{row['name']}</a></TableCell>
                        <TableCell>{new Date(row['moving_time'] * 1000).toISOString().slice(11, 19)}</TableCell>
                        <TableCell>{row['sport_type']}</TableCell>
                        <TableCell>{row['start_date_local'].replace('T',' ').replace('Z','')}</TableCell>
                        <TableCell>{row['distance']}</TableCell>
                        <TableCell>{row['photos']}</TableCell>
                        <TableCell>{row['manual']}</TableCell>
                     </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer> 
            <Pagination backwardText="Previous page" forwardText="Next page" itemsPerPageText="Items per page:" onChange={this.changePage} page={this.state.Page} pageSize={this.state.PageSize} pageSizes={[25,50,75]} size="sm" totalItems={this.state.Activities.length} />
         </div>
      )
   }
}

export default Activity;
