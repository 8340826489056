import React, { Component } from 'react';
import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderPanel,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
} from 'carbon-components-react';
import {
  UserAvatar20,
} from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import UserPanel from "./UserPanel";

class PageHeader extends Component {

  constructor(props) {
       super(props);
       this.state = {
          Login: "",
          isClicked: false,
       };
       this.userPanelProps = {
           expanded: false
       }
   }

   handleClick() {
       this.setState(state => ({
           isClicked: !state.isClicked
       }));
       this.userPanelProps.expanded = !this.state.isClicked;
   }

   componentDidMount() {
      this.setState({ Login: this.props.Login });
   }

   render() {
    return (
      <Header aria-label="Team Challenge">
        <SkipToContent />
        <HeaderMenuButton
          aria-label="Open menu"
          onClick={() => this.setState({LoginScreen: true}) }
        />
        <HeaderName element={Link} to="/" prefix="IBM">
          Team Challenge
        </HeaderName>
        <HeaderNavigation aria-label="Team Challenge">
{/*
          <HeaderMenuItem element={Link} to="/repos">
            Repositories
          </HeaderMenuItem>
*/}
        </HeaderNavigation>
{/*
        <SideNav
          aria-label="Side navigation"
          expanded={isSideNavExpanded}
          isPersistent={false}>
          <SideNavItems>
            <HeaderSideNavItems>
              <HeaderMenuItem element={Link} to="/repos">
                Repositories
              </HeaderMenuItem>
            </HeaderSideNavItems>
          </SideNavItems>
        </SideNav>
*/}
        <HeaderGlobalBar>
{/*
           <HeaderGlobalAction aria-label="Notifications">
             <Notification20 />
           </HeaderGlobalAction>
*/}
          <HeaderGlobalAction aria-label="User Avatar">
            <UserAvatar20 onClick={this.handleClick.bind(this)} />
          </HeaderGlobalAction>
{/*
          <HeaderGlobalAction aria-label="App Switcher">
            <AppSwitcher20 />
          </HeaderGlobalAction>
*/}
        </HeaderGlobalBar>
        <HeaderPanel {...this.userPanelProps} style={{bottom: "80%"}} aria-label="User Avatar Panel">
{/*
            <UserPanel {...this.state}/>
*/}
        </HeaderPanel>
       </Header>
    )
  }
}

export default PageHeader;

