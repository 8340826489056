import React, { Component, useState } from 'react';
import {
   Modal,
   Button,
   ButtonSet,
   UnorderedList,
   ListItem,
} from 'carbon-components-react';
import axios from 'axios';
import config from '../../config';

class FAQ extends Component {
   constructor(props) {
      super(props);
      this.state = {
         faq_list: [],
      };
   }

   getFAQ = async () => {
      let URL = "/api/get_faq"
      await fetch(URL, { method: "post" })
         .then(res => {
            return res.json();
         })
         .then((result) => {
            // alert(JSON.stringify(result));
            this.setState({ faq_list: result});
        })
   }

   componentDidMount() {
      this.getFAQ();
   }

   render() {
      var last_section="";
      return (
         <div>
         <UnorderedList>
         { Object.keys(this.state.faq_list).map ( rowkey => (
            <ListItem>
            {rowkey}
            <UnorderedList nested>
               { this.state.faq_list[rowkey].map( subrow => (
               <ListItem>
               {subrow.Question}
               <UnorderedList nested>
                  <ListItem>
                  {subrow.Answer}
                  </ListItem>
               </UnorderedList>
               </ListItem>
               )) }
            </UnorderedList>
            </ListItem>
          ))
         }
         </UnorderedList>
         </div>
      )
   }
}

  

export default FAQ;
