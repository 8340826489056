import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Motd extends Component {
  constructor(props) {
    super(props);
    this.state = {
       Title: "",
       Message: ""
    }
  }

  getMotd = async () => {
    let URL = "/get_motd"
    await fetch(URL, { method: "get" })
      .then(res => {
        return res.json();
     })
      .then((result) => {
//        if ('Title' in result) {
          this.setState( { Message: result} );
//        }
//        else {
//          this.setState( {Title: "", Message: ""} );
//        }
      })
  }

 componentDidMount() {
    var oidc_userdata = this.getMotd();
  }

   render() {
    return (
      <div className="Motd">
        <h3>
        {(this.state.Message != "") && this.state.Message.map((row) => (
          <div>
          <p style={ { 'marginTop': '1rem', 'font-weight': 'bold', 'font-style': 'italic' } }>{ row.Title }</p>
          <p style={ { 'font-style': 'italic' } }>{ row.Message }</p>
          </div>
        ))}
        </h3>
      </div>
    )
  }
}

export default Motd;