import React, { Component, useState, Fragment } from 'react';
import {
   Table,
   TableHead,
   TableHeader,
   TableRow,
   TableBody,
   TableCell,
   TableContainer, 
   TableToolbar,
   TableToolbarContent,
   Button,
   ButtonSet,
   Link,
   Form,
   TextInput,
   TextArea,
   Modal,
   TableExpandHeader,
   TableExpandRow,
   TableExpandedRow,
} from 'carbon-components-react';
import axios from 'axios';
import config from '../../config';

class Challenge extends Component {
   constructor(props) {
      super(props);
      this.state = {
         DetailsFormVisible: false,
         DetailsFormID: 0,
         CreateFormVisible: false,
         CreateFormName: "",
         CreateFormDescription: "",
         CreateFormWeight: "",
         CreateFormCode: "",
         CreateFormDetails: "",
         CreateFormIcon: "",
         CreateFormRow: 0,
         CreateFormColumn: 0,
         id: 0,
	 contest: "",
         ContestSelected: 0,        // ID of selected contest
         Name: "",
         Description : "",
  	 Challenges: [],
         Leader: false,
         header:["Name","Weight","Your Score","Score Value","Score Details","Details"],
         Login:""
      };
      this.handleChangeName = this.handleChangeName.bind(this);
      this.handleChangeDescription = this.handleChangeDescription.bind(this);
      this.handleChangeWeight = this.handleChangeWeight.bind(this);
      this.handleChangeCode = this.handleChangeCode.bind(this);
      this.handleChangeDetails = this.handleChangeDetails.bind(this);
      this.handleChangeIcon = this.handleChangeIcon.bind(this);
      this.handleChangeRow = this.handleChangeRow.bind(this);
      this.handleChangeColumn = this.handleChangeColumn.bind(this);
      this.handleCreateSubmit = this.handleCreateSubmit.bind(this);
      this.handleOnExpandRow = this.handleOnExpandRow.bind(this);
   }

   async getContestData (contest_ID) {
      await axios.post(config.api_hostname + ':' + config.api_port + '/api/get_contest_challenges',
                       { ID: contest_ID },
                        { headers: {   'content-type': 'application/x-www-form-urlencoded',
//                                       'Access-Control-Allow-Origin' : '*',
//                                       'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept'
                            }
                     })
      .then((response) => {
            const data = response.data;
            for (var i = 0; i < data.Challenges.length; i++)
               data.Challenges[i].IsExpanded = false;
            this.setState({ Name: data.Name, Description: data.Description, Challenges: data.Challenges, Leader: data.Leader});
            return data.Challenges;
         }
      )
   }

   componentDidMount() {
      this.setState({ Login: this.props.Login });
      this.setState({ ContestSelected: this.props.Selection });
      var data = this.getContestData(this.props.Selection);
   }
   handleOnExpandRow(rowId) {
      var c = this.state.Challenges;
      for (var i = 0; i < c.length; i++) 
         if (c[i].ID === rowId)
            c[i].IsExpanded = ! c[i].IsExpanded;
      this.setState( { Challenges: c } );
   };

   hideDetailsForm () {
      this.setState({ DetailsFormVisible: false });
   }
   showDetailsForm ( id ) {
      this.setState({ DetailsFormVisible: true });
      this.setState({ DetailsFormID: id });
   }

   showCreateForm = () => {
      this.setState({ CreateFormVisible: true  });
   }
   hideCreateForm = () => {
      this.setState({ CreateFormVisible: false  });
   }
   handleChangeName(event) {
     this.setState({CreateFormName: event.target.value});
   }
   handleChangeDescription(event) {
     this.setState({CreateFormDescription: event.target.value});
   }
   handleChangeWeight(event) {
     this.setState({CreateFormWeight: event.target.value});
   }
   handleChangeCode(event) {
     this.setState({CreateFormCode: event.target.value});
   }
   handleChangeDetails(event) {
     this.setState({CreateFormDetails: event.target.value});
   }
   handleChangeIcon(event) {
     this.setState({CreateFormIcon: event.target.value});
   }
   handleChangeRow(event) {
     this.setState({CreateFormRow: event.target.value});
   }
   handleChangeColumn(event) {
     this.setState({CreateFormColumn: event.target.value});
   }
   handleCreateSubmit(event) {
      var msg = "";
      if (this.state.CreateFormName.length < 8)  msg = msg + "Name too short, must be at least 8 characters\r\n";
      if (this.state.CreateFormName.length > 64) msg = msg + "Name too long, maximum 64 characters\r\n";
      if (this.state.CreateFormDescription.length < 8) msg = msg + "Description too short, must be at least 8 characters\r\n";
      if (this.state.CreateFormDescription.length > 256) msg = msg + "Description too long, maximum 256 characters\r\n";
      if (isNaN(parseInt(this.state.CreateFormWeight))) msg = msg + "Weight number not a positive integer\r\n";
      if (this.state.CreateFormDetails.length < 1) msg = msg + "Details missing\r\n";
      if (this.state.CreateFormDetails.length > 2048) msg = msg + "Details too long, maximum 256 characters\r\n";
      if (this.state.CreateFormIcon.length < 1) msg = msg + "Icon name missing\r\n";
      if (this.state.CreateFormIcon.length > 256) msg = msg + "Icon name too long, maximum 256 characters\r\n";
      if (isNaN(parseInt(this.state.CreateFormRow))) msg = msg + "Row number not a positive integer\r\n";
      if (isNaN(parseInt(this.state.CreateFormColumn))) msg = msg + "Column number not a positive integer\r\n";
      if ( msg.length > 0 )
         alert(msg);
      else {
         axios.post(config.api_hostname + ':' + config.api_port + '/api/create_challenge',
                       { Login: this.state.Login,
                         Name: this.state.CreateFormName,
                         Description: this.state.CreateFormDescription,
                         Weight: this.state.CreateFormWeight,
                         Code: this.state.CreateFormCode,
                         Details: this.state.CreateFormDetails,
                         Icon: this.state.CreateFormIcon,
                         Row: this.state.CreateFormRow,
                         Column: this.state.CreateFormColumn,
                         Contest: this.state.ContestSelected },
                       { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then((response) => {
            this.getContestData(this.state.ContestSelected);
            this.setState({ CreateFormVisible: false  });
         })
      }
   }

   render() {
//      var data = this.state.Challenges;
      if (this.state.ContestSelected !== this.props.Selection) {
         var x = this.getContestData(this.props.Selection);
//         p.then(function (response) {
//            data = response;
            this.setState({ ContestSelected: this.props.Selection});
//         })
      }

      return (
         <div>
            <TableContainer title={"Contest: " + this.state.Name} description={this.state.Description}>
               <TableToolbar aria-label="data table toolbar">
                  <TableToolbarContent>
                     <Button onClick={() => this.showCreateForm()} disabled={(! this.state.Leader)}>Create New Challenge</Button>
                  </TableToolbarContent>
               </TableToolbar>
               <Table size='compact' useZebraStyles={false}>
                  <TableHead>
                     <TableRow>
                        <TableExpandHeader />
                           {this.state.header.map((header) => (
                              <TableHeader id={header.key} key={header}>{header}</TableHeader>
                           ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     { this.state.Challenges.map((row) => (
                        <React.Fragment key={row.id}>
                        <TableExpandRow isExpanded={row['IsExpanded']} onExpand={() => this.handleOnExpandRow(row['ID'])} key={row['ID']}>
                           <TableCell key='Name'>{row['Name']}</TableCell>
                           <TableCell key='Weight'>{0 + row['Weight']} %</TableCell>
                           <TableCell key='Score'>{0 + row['Score']} %</TableCell>
                           <TableCell key='Value'>{row['Value']}</TableCell>
                           <TableCell key='Data'>{row['Data']}</TableCell>
                           <TableCell><Link onClick={() => this.showDetailsForm(row["ID"])} >Details</Link></TableCell>
                        </TableExpandRow>
                        <TableExpandedRow colSpan={this.state.header.length+1} className="demo-expanded-td">
                           <h6>Description</h6>
                           <div>{row["Description"]}</div>
                         </TableExpandedRow>
                        </React.Fragment>
                     ))}
                  </TableBody>
               </Table>   
            </TableContainer> 

	    {this.state.DetailsFormVisible && (
            <Modal
               open
               modalHeading={this.state.Challenges.find(element => element.ID === this.state.DetailsFormID).Name}
               modalLabel="Description"
               primaryButtonText="Close"
               onRequestSubmit={() => this.hideDetailsForm()}
               onRequestClose={() => this.hideDetailsForm()}>
               <p>
               <img src={this.state.Challenges.find(element => element.ID === this.state.DetailsFormID).Icon} width="64" height="64" />
               </p>
               <p>From: {this.state.Challenges.find(element => element.ID === this.state.DetailsFormID).From.replace('T',' ').slice(0,19)}</p>
               <p>Till: {this.state.Challenges.find(element => element.ID === this.state.DetailsFormID).Till.replace('T',' ').slice(0,19)}</p>
               <p>&nbsp;</p>
               <p style={{ marginBottom: '1rem' }}>
                  <div className="content" dangerouslySetInnerHTML={{__html: this.state.Challenges.find(element => element.ID === this.state.DetailsFormID).Details }}></div>
               </p>
            </Modal>
            )}

	    {this.state.CreateFormVisible && (
            <Modal
               open
               modalHeading="Add a new Challenge"
               modalLabel="Create Challenge"
               primaryButtonText="Add"
               secondaryButtonText="Cancel"
               onRequestSubmit={() => this.handleCreateSubmit()}
               onSecondarySubmit={() => this.hideCreateForm()}
               onRequestClose={() => this.hideCreateForm()}>
               <p style={{ marginBottom: '1rem' }}>
                  Add a new challenge to the contest.
               </p>
               <TextInput
                  data-modal-primary-focus
                  id="challenge_name"
                  labelText="Challenge name"
                  placeholder="Write the name of the challenge"
                  style={{ marginBottom: '1rem' }}
                  onChange={this.handleChangeName}
               />
               <TextArea
                  data-modal-primary-focus
                  id="challenge_description"
                  labelText="Full description"
                  placeholder="Description of the challenge"
                  rows={10}
                  style={{ marginBottom: '1rem' }}
                  onChange={this.handleChangeDescription}
               />
               <TextInput
                  data-modal-primary-focus
                  id="challenge_weight"
                  labelText="Challenge Weight Percentage"
                  placeholder="0"
                  style={{ marginBottom: '1rem' }}
                  onChange={this.handleChangeWeight}
               />
               <TextInput
                  data-modal-primary-focus
                  id="challenge_code"
                  labelText="Challenge Code"
                  placeholder="Analytics function CODE"
                  style={{ marginBottom: '1rem' }}
                  onChange={this.handleChangeCode}
               />
               <TextArea
                  data-modal-primary-focus
                  id="challenge_details"
                  labelText="Details (HTML)"
                  placeholder="Full details of the challenge"
                  rows={10}
                  style={{ marginBottom: '1rem' }}
                  onChange={this.handleChangeDetails}
               />
               <TextInput
                  data-modal-primary-focus
                  id="challenge_icon"
                  labelText="Challenge Icon File"
                  placeholder="Icon"
                  style={{ marginBottom: '1rem' }}
                  onChange={this.handleChangeIcon}
               />
               <TextInput
                  data-modal-primary-focus
                  id="challenge_row"
                  labelText="Challenge Row number"
                  placeholder="Row number"
                  style={{ marginBottom: '1rem' }}
                  onChange={this.handleChangeRow}
               />
               <TextInput
                  data-modal-primary-focus
                  id="challenge_column"
                  labelText="Challenge Column number"
                  placeholder="Column number"
                  style={{ marginBottom: '1rem' }}
                  onChange={this.handleChangeColumn}
               />
            </Modal>
            )}
         </div>
      )
   }
}

export default Challenge;
