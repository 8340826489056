import React, { Component, useState } from 'react';
import {
   Table,
   TableHead,
   TableHeader,
   TableRow,
   TableBody,
   TableCell,
   TableContainer, 
   TableToolbar,
   TableToolbarContent,
   TableExpandHeader,
   TableExpandRow,
   TableExpandedRow,
   DataTable,
   Button,
   ButtonSet,
   Form,
   TextInput,
   Modal,
   Pagination,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

class TeamBoard extends Component {
   constructor(props) {
      super(props);
      this.state = {
         IsLoaded: false,
         DetailsFormVisible: false,
         DetailsFormID: 0,
         Team: 0,                   // My team ID
         TeamData: "", 
         ContestSelected: 0,
         Login:"",
  	 Challenges: [],
      };
   }

   async getContestData (contest_ID) {
      await axios.post(config.api_hostname + ':' + config.api_port + '/api/get_contest_teams',
                       { ID: contest_ID }  /* ,
                       { headers: { 'content-type': 'application/x-www-form-urlencoded' } } */ )
         .then((response) => {
            this.setState({ Team: response.data.Team, IsLoaded: true });
         })
   }

   async getTeamData (contest_ID, team) {
      await axios.post(config.api_hostname + ':' + config.api_port + '/api/get_team_scores',
                       { Team: team } /* ,
                       { headers: { 'content-type': 'application/x-www-form-urlencoded' } } */ )
            .then((teamdata) => {
               axios.post(config.api_hostname + ':' + config.api_port + '/api/get_contest_challenges',
                       { ID: contest_ID } /*,
                       { headers: { 'content-type': 'application/x-www-form-urlencoded' } } */ )
                  .then((challengedata) => {
                     this.setState({ TeamData: teamdata.data, Challenges: challengedata.data.Challenges });
                  })
            })
   }

   hideDetailsForm () {
      this.setState({ DetailsFormVisible: false });
   }
   showDetailsForm ( id ) {
      this.setState({ DetailsFormVisible: true, DetailsFormID: id });
   }

   componentDidMount() {
      this.setState({ Login: this.props.Login });
      if ((this.state.Team > 0) && (this.state.TeamData === "")) {
         var data = this.getTeamData(this.props.Selection);
      }
   }

   render() {
      if (this.state.ContestSelected !== this.props.Selection) {
         var data = this.getContestData(this.props.Selection);
         this.setState({ ContestSelected: this.props.Selection, Team: 0, TeamData: ""});
      }
      if ((this.state.Team > 0) && (this.state.TeamData === "")) {
         var data = this.getTeamData(this.state.ContestSelected, this.state.Team);
      }

      return (
         <div>
         {(this.state.Team == 0) && (this.state.IsLoaded) && (
	    <p>You are not a member of a team yet, please go to All Teams and join an existing team or create your own new team</p>
         )}
        
         { (this.state.Team > 0) && (this.state.TeamData !== "") && (this.state.IsLoaded) && (
            <div>
            <table>
                  {this.state.TeamData.Scores.map((row) => ( 
                     <tr height="80" align="center">
                        {row.map((col) => ( (col !== null) && (<td width="80" align="center"><img style={{backgroundColor:"#"+(Math.round(16+((100-col.Score) * 2.39))).toString(16).toUpperCase()+"FF"+(Math.round(16+((100-col.Score) * 2.39))).toString(16).toUpperCase()}} src={col.Icon} width="64" height="64" /><br />{col.Score}%</td>)))}
                     </tr>
                  ))}
            </table>   

            <TableContainer title={this.state.TeamData.Name + " : " + this.state.TeamData.Total.toFixed(2) + "%"} description={this.state.TeamData.Description}>
               <Table size='compact' useZebraStyles={false}>
                  <TableHead>
                     <TableRow>
                           <TableHeader>Challenge</TableHeader>
                           <TableHeader>Weight</TableHeader>
                           <TableHeader>Value</TableHeader>
                           <TableHeader>Score</TableHeader>
                           <TableHeader>Total</TableHeader>
                           <TableHeader>Data</TableHeader>
                           <TableHeader>Details</TableHeader>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {this.state.TeamData.Scores.map((row) => ( row.map((col) => ( (col !== null) && (
                        <TableRow>
                              <TableCell>{col.Name}</TableCell>
                              <TableCell>{col.Weight}%</TableCell>
                              <TableCell>{col.Value}</TableCell>
                              <TableCell>{col.Score}%</TableCell>
                              <TableCell>{col.Total}%</TableCell>
                              <TableCell>{col.Data}</TableCell>
                              <TableCell><Link onClick={() => this.showDetailsForm(col["ID"])} >Details</Link></TableCell>
                        </TableRow>
                             ))) ))}
                  </TableBody>
               </Table>   
            </TableContainer> 
	    </div>
            )}

	    {this.state.DetailsFormVisible && (
            <Modal
               open
               modalHeading={this.state.Challenges.find(element => element.ID === this.state.DetailsFormID).Name}
               modalLabel="Description"
               primaryButtonText="Close"
               onRequestSubmit={() => this.hideDetailsForm()}
               onRequestClose={() => this.hideDetailsForm()}>
               <p>
               <img src={this.state.Challenges.find(element => element.ID === this.state.DetailsFormID).Icon} width="64" height="64" />
               </p>
               <p style={{ marginBottom: '1rem' }}>
                  <div className="content" dangerouslySetInnerHTML={{__html: this.state.Challenges.find(element => element.ID === this.state.DetailsFormID).Details }}></div>
               </p>
            </Modal>
            )}
         </div>
      )
   }
}

export default TeamBoard;
