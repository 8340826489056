import React, { Component, useState } from 'react';
import {
   Modal,
   Button,
   ButtonSet,
} from 'carbon-components-react';
import axios from 'axios';
import config from '../../config';

class Profile extends Component {
   constructor(props) {
      super(props);
      this.state = {
         StravaLoginFormVisible: false,
         Authenticated: false,
         Email: "",
         UserName: "",
         FirstName: "",
         LastName: "",
         StravaID: 0,
         Login: "",
         Organisation : "",
	 Sync: false
      };
      this.showStravaLoginForm = this.showStravaLoginForm.bind(this);
      this.syncStrava = this.syncStrava.bind(this);
   }

   getUserProfile = async () => {
      let URL = "/get_user_profile"
      await fetch(URL, { method: "get" })
         .then(res => {
            return res.json();
         })
         .then((result) => {
            // alert(JSON.stringify(result));
            this.setState({ oidc_data: JSON.stringify(result)});
            if ('Email' in result) {
                 this.setState( {Authenticated: true, 
                                 Login: result.Login,
                                 Email: result.Email,
                                 UserName: result.UserName,
                                 FirstName: result.FirstName,
                                 LastName: result.LastName,
                                 StravaID: result.StravaID,
                                 Sync: result.Sync,
                                 Organisation: result.Organisation });
                 this.props.setAuth (result.Login);
                 if (result.StravaID === 0) this.setState( {StravaLoginFormVisible: true} );
            }
            else {
                 this.setState( {Authenticated: false, Login: ""} );
                 this.props.setAuth ("");
            }
        },
        (error) => {
           this.setState( {Authenticated: false, Login: "" } );
        })
   }

   showStravaLoginForm(status) {
      this.setState({ StravaLoginFormVisible: status  });
   }

   syncStrava() {
      axios.post(config.api_hostname + ':' + config.api_port + '/api/get_member_stravadata',
                       { Login: this.state.Login },
                       { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
      .then((response) => {
         this.setState({ Sync: false });
      })
   }

   componentDidMount() {
      this.setState({ Login: this.props.Login, Authenticated: (this.state.Login !== "") });
      var oidc_userdata = this.getUserProfile();
   }

   render() {
      var strava_text = "Strava ID unknown, link with the button below";
      var strava_link = "http://www.strava.com/oauth/authorize?state="+this.state.Login+"&client_id="+config.strava_client_id+"&response_type=code&redirect_uri="+config.strava_redirect_uri+"/exchange_token&approval_prompt=force&scope=read_all,profile:read_all,activity:read_all";
      if (this.state.StravaID > 0)
         strava_text = String(this.state.StravaID);

      return (
         <div>
            <h2 className="landing-page__subheading">Welcome {this.state.UserName}</h2>
            <h3 className="landing-page__p">We have following data on file:</h3>
            <p><b>E-mail:</b> { this.state.Email } </p>
            <p><b>User Name:</b> { this.state.UserName } </p>
            <p><b>First Name:</b> { this.state.FirstName } </p>
            <p><b>Last Name:</b> { this.state.LastName } </p>
            <p><b>Organisation:</b> { this.state.Organisation } </p>
            <p><b>Your Strava ID:</b> { strava_text } </p>
            <p><b>Single Sign On ID:</b> { this.state.Login } </p>
            <p>&nbsp;</p>
            { (this.state.StravaID === 0) && (<Button onClick={() =>
               this.showStravaLoginForm(true)}>Strava Link</Button> ) } 

         {(this.props.Authenticated === true) && (this.state.StravaLoginFormVisible === true) && (
         <Modal
            open
            modalHeading="Missing link to Strava"
            modalLabel="Strava"
            primaryButtonText="Link your Strava account"
            secondaryButtonText="I do this later"
            onSecondarySubmit={() => this.showStravaLoginForm(false)}
            onRequestClose={() => this.showStravaLoginForm(false)}
            onRequestSubmit={() => window.open(strava_link, "_blank")}>
            <p style={{ marginBottom: '1rem' }}>You must link to your Strava account to participate to the Team Challeng!</p>
            <p style={{ marginBottom: '1rem' }}>Please click the link button below, login to Strava with your Strava credentials and approve the access to your data. Do not change the access request settings.</p>
         </Modal>
         )}

         {(this.props.Authenticated === true) && (this.state.StravaID > 0) && (
            <div>
	         	<p>STRAVA DATA IS INGESTED AUTOMATICALLY, please be patient, it usually takes a couple of minutes to receive updates from Strava.</p>
               <p><b>ONLY</b> if you think some data is missing you can force a manual resync.</p>
         	<Button onClick={()=>this.syncStrava()} disabled={(this.state.Sync === false)}>
                Manually sync my Strava data
                </Button>
		         <p>Maximum one Sync action per 24 hours</p>
            </div>
         )}
         </div>

      )
   }
}

export default Profile;
