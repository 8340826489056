import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PageFooter extends Component {

   render() {
    return (
      <div className="Footer">
      <footer>
          The Team Challenge Site
      </footer>
      </div>
    )
  }
}

export default PageFooter;

